import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})

export class PhoneFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || value.length !== 10) {
      return value; // Devuelve el valor original si no es válido
    }

    // Formatear el número
    const areaCode = value.substring(0, 4);
    const number = value.substring(4);

    return `(${areaCode}) ${number}`;
  }
}