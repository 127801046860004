import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, map, of } from 'rxjs';
import { SettingsService } from '../settings.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    // sesion de usuario
    private session: Session | null= null;

    constructor(
        private router: Router,
        private http: HttpClient,
        private settings: SettingsService,
    ) {

    }

    /**
     * Almacena la sesion en memoria
     * @param data datos de sesion de usuario
     */
    setSession(data: Session) {
        this.session = data;
        localStorage.setItem('sessionCrediagilWeb', JSON.stringify(data));
    
    }

    /**
     * Almacena la sesion en memoria
     * @param data datos de sesion de usuario
     */
    getSession() {
        return of(this.session);
    }

    existSession() {
        return this.session !== null;
    }

    /**
     * Retorna el token de acceso del usuario
     */
    public getAccessToken(): string {
        let storage: any;

        if (typeof localStorage !== 'undefined') {
            storage = localStorage.getItem('sessionCrediagilWeb');
        }
        
        return storage ? JSON.parse(storage).token : null;
    }

    /**
     * Retorna un observable, despues de verificar si el token se encuentra en el localStorage
     */
    public verificarLocalStore(): Observable<boolean> {
        return of(localStorage.getItem('sessionCrediagilWeb')).pipe(
            map(storage => {
                if(storage){
                    const storageJson = JSON.parse(storage ?? '');
                    this.setSession({token: storageJson.token, usuario: storageJson.usuario});
                }
        
                return !!storage;
            })
        );
    }

    logout(path = '/solicitud-credito/login'): void {
        localStorage.removeItem("sessionCrediagilWeb");
        
        this.session = null;

        console.log('path: ', path);
        this.router.navigate([path]);
    }

    reemplazarTokenLocal(data: any) {
        const session = JSON.parse(localStorage.getItem('sessionCrediagilWeb') ?? '');
        session.token = data.token;
        session.moduloId = data.moduloId;
        localStorage.setItem('sessionCrediagilWeb', JSON.stringify(session));
        this.session = session;
    }
}

export interface Session {
    token: string;
    usuario: string;
}