import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: number | string): string {
    if (!value) {
      return '';
    }

    // Aseguramos que el valor sea un número
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue)) {
      return value.toString(); // Si no es un número, lo devolvemos como está
    }

    // Convertimos a string con separadores de miles (.)
    return numericValue
      .toFixed(0) // Opcional: sin decimales; puedes usar `toFixed(2)` para dos decimales
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
